import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  PageHeader,
  Button,
  Card,
  Descriptions,
  Badge,
  Tooltip as AntTooltip,
  Divider,
} from "antd";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";
import socketIOClient from "socket.io-client";
import { Chart, Interval, Tooltip, Coordinate } from "bizcharts";
// import axios from "axios";

import SEO from "@components/common/seo";
import DescriptionsTitle from "@components/common/descriptionsTitle";

import { queries } from "@helpers/queryStrings";
import { formatISODate } from "@helpers/tools";
import { pieChartData } from "@helpers/const";

const UNDEFINED = "-";
// const API_URL_MICRO_1 = `${process.env.API_URL_MICRO_1}`;

const Website = ({ id }) => {
  const [visitors, setVisitors] = useState([]);
  const { loading, data } = useQuery(queries.collaborative, {
    variables: {
      id,
    },
  });

  const findSiteVisitor = (id: number) => {
    let totalSpecificVistors = 0;

    visitors.map(
      (res: Object) => res.sites_id === Number(id) && totalSpecificVistors++
    );

    return totalSpecificVistors;
  };

  const cols = {
    percent: {
      formatter: (val) => {
        val = val * 100 + "%";
        return val;
      },
    },
  };

  useEffect(() => {
    const socket = socketIOClient("https://api.im90s.org");
    socket.on("visitor_join", (data: Array<mixed>) => {
      setVisitors(data);
    });

    socket.emit("visitor_join", {
      ip: "",
      host: "",
      sites_id: "",
    });
  }, []);

  const chartHeight = 200;

  return (
    <>
      <SEO title={`Website: ${data && data.collaborative.company.text}`} />
      <PageHeader
        title={
          <div style={{ textTransform: "capitalize" }}>
            {data ? data.collaborative.company.text : UNDEFINED}
          </div>
        }
        subTitle={`ID: ${data ? data.collaborative.id : UNDEFINED}`}
        extra={[
          <Button key={1} disabled>
            Edit
          </Button>,
        ]}
        onBack={() => window.history.back()}
        // breadcrumb={{ routes }}
      />
      <WebsitesWrapper loading={loading.toString()}>
        <Card loading={loading} bordered={loading}>
          <DescriptionsTitle
            title="Assignments"
            descriptionItem={[
              {
                label: "Dev Date",
                val:
                  data && data.collaborative.dev_date
                    ? data.collaborative.dev_date
                    : UNDEFINED,
              },
              {
                label: "Signoff Date",
                val:
                  data && data.collaborative.signoff_date
                    ? data.collaborative.signoff_date
                    : UNDEFINED,
              },
              {
                label: "Created",
                val:
                  data && formatISODate(data.collaborative.created_at)
                    ? formatISODate(data.collaborative.created_at)
                    : UNDEFINED,
              },
            ]}
          />

          <DescriptionsTitle
            title="Costing"
            descriptionItem={[
              {
                label: "Budget",
                val:
                  data && data.collaborative.budget
                    ? data.collaborative.budget
                    : UNDEFINED,
              },
              {
                label: "Server Fees(PA)",
                val:
                  data && data.collaborative.server_recurrent
                    ? data.collaborative.server_fees
                    : UNDEFINED,
              },
            ]}
          />
          <DescriptionsTitle
            title="Client's Infos"
            descriptionItem={[
              {
                label: "Owners",
                val:
                  data &&
                  data.collaborative.owner.map((res, index) => {
                    return <div key={index}>{res.name}</div>;
                  }),
              },
              {
                label: "Remark",
                val:
                  data && data.collaborative.remark
                    ? data.collaborative.remark
                    : UNDEFINED,
              },
            ]}
          />

          <Row style={{ margin: "30px" }}>
            <Descriptions
              title="Project Details"
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item>
                {data &&
                  data.collaborative.projects.map((res, index) => {
                    return (
                      <div key={index}>
                        <DescriptionsTitle
                          title={
                            <AntTooltip
                              placement="top"
                              title={
                                res.status
                                  ? res.status.toUpperCase()
                                  : UNDEFINED
                              }
                            >
                              <Badge
                                status={
                                  res.status === "live"
                                    ? "success"
                                    : res.status === "down"
                                    ? "warning"
                                    : "error"
                                }
                                text={res.name}
                              />
                            </AntTooltip>
                          }
                          margin="0 0 15px 0"
                          descriptionItem={[
                            {
                              label: "Views",
                              val: res.views.length,
                            },
                            {
                              label: "Enquiries",
                              val: UNDEFINED,
                            },
                            {
                              label: "Visitors(Live)",
                              val: findSiteVisitor(res.id),
                            },
                          ]}
                        />
                        <Row>
                          <Col span={24} sm={12} md={8}>
                            <Card loading={loading} title={"Visitors Country"}>
                              <Chart
                                height={chartHeight}
                                data={pieChartData}
                                scale={cols}
                                autoFit
                                pure
                              >
                                <Coordinate type="theta" radius={0.75} />
                                <Tooltip showTitle={false} />
                                <Interval
                                  position="percent"
                                  adjust="stack"
                                  color="item"
                                  style={{
                                    lineWidth: 1,
                                    stroke: "#fff",
                                  }}
                                  label={[
                                    "views",
                                    {
                                      content: (data) => {
                                        return `${data.item}: ${
                                          data.percent * 100
                                        }%`;
                                      },
                                    },
                                  ]}
                                />
                              </Chart>
                            </Card>
                          </Col>
                        </Row>
                        <DescriptionsTitle
                          size={"small"}
                          margin="15px 0 15px 0"
                          descriptionItem={[
                            {
                              label: "IP",
                              val: res.server.ip,
                            },
                            {
                              label: "CI/CD",
                              val: res.ci_cd ? (
                                <div style={{ textTransform: "capitalize" }}>
                                  {res.ci_cd}
                                </div>
                              ) : (
                                UNDEFINED
                              ),
                            },
                            {
                              label: "DB Host",
                              val: res.db_host_connect
                                ? res.db_host_connect.ip
                                : UNDEFINED,
                            },
                            {
                              label: "DB Structure",
                              val: res.db_struct ? res.db_struct : UNDEFINED,
                            },
                            {
                              label: "Domain",
                              val: (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={res.domain}
                                >
                                  {res.domain ? res.domain : UNDEFINED}
                                </a>
                              ),
                            },
                            {
                              label: "Project Type",
                              val: res.project_type
                                ? res.project_type.text
                                : UNDEFINED,
                            },
                            {
                              label: "Port",
                              val: res.port,
                            },
                            {
                              label: "Website Type",
                              val: res.website_type ? (
                                <div style={{ textTransform: "capitalize" }}>
                                  {res.website_type.text}
                                </div>
                              ) : (
                                UNDEFINED
                              ),
                            },
                            {
                              label: "Server",
                              val: res.server ? res.server.name : UNDEFINED,
                            },
                            {
                              label: "Vendor",
                              val: (
                                <div style={{ textTransform: "capitalize" }}>
                                  {res.server.vendor.replace("_", " ")}
                                </div>
                              ),
                            },
                            {
                              label: "Remark",
                              val: res.remark ? res.remark : UNDEFINED,
                            },
                          ]}
                        />
                        <Divider />
                      </div>
                    );
                  })}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Card>
      </WebsitesWrapper>
    </>
  );
};

export default Website;

const WebsitesWrapper = styled.div`
  > .ant-card {
    > .ant-card-body {
      padding: ${(props) => (props.loading === "true" ? "24px" : 0)};
    }
  }

  .ant-descriptions-title {
    margin-bottom: 10px;
  }
`;
