import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { queries } from "@helpers/queryStrings";
import { getUser } from "@services/auth";

const withThisUser = ComponentToWrap => props => {
  const { id } = getUser();
  const { data, loading } = useQuery(queries.user, {
    variables: { id: Number(id) }
  });

  return (
    <ComponentToWrap
      {...props}
      userDetails={data && data.user}
      loading={loading}
    />
  );
};

export default withThisUser;
