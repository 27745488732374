import React from "react";
import { Row, Col, PageHeader, Button, Card } from "antd";
import { useQuery } from "@apollo/react-hooks";
import styled from "styled-components";

import SEO from "@components/common/seo";
import WebsiteCard from "@components/common/websiteCard";

import { queries } from "@helpers/queryStrings";

const Websites = () => {
  const { loading, data } = useQuery(queries.collaboratives);

  return (
    <>
      <SEO title="Websites" />
      <PageHeader
        title="Websites"
        subTitle="All websites"
        extra={[
          <Button key={1} disabled>
            Add Website
          </Button>,
        ]}
        // breadcrumb={{ routes }}
      />
      <WebsitesWrapper loading={loading.toString()}>
        <Card loading={loading} bordered={loading}>
          <Row>
            {data &&
              data.collaboratives.map((res, index) => {
                return (
                  <Col key={index} xs={24} sm={12} md={8} lg={6}>
                    <WebsiteCard data={res} />
                  </Col>
                );
              })}
          </Row>
        </Card>
      </WebsitesWrapper>
    </>
  );
};

export default Websites;

const WebsitesWrapper = styled.div`
  > .ant-card {
    > .ant-card-body {
      padding: ${(props) => (props.loading === "true" ? "24px" : 0)};
    }
  }
`;
