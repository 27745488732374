import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import Divider from "@material-ui/core/Divider";

// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import StarBorder from "@material-ui/icons/StarBorder";
import Collapse from "@material-ui/core/Collapse";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import ListItemIcon from "@material-ui/core/ListItemIcon";

import logoWhiteImg from "@images/logos/logo.png";

import { menu, color } from "@helpers/const";

const useStyles = makeStyles({
  list: {
    width: "100%",
  },
});

const SideBar = ({ menuExpand, triggerMenu }) => {
  const classes = useStyles();
  const [open] = React.useState(false);
  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    triggerMenu(false);
  };

  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      // onClick={toggleDrawer(side, false)}
      // onKeyDown={toggleDrawer(side, false)}
    >
      <Logo>
        <img
          style={{
            width: "auto",
            height: "40px",
            margin: "0 15px 0 0",
          }}
          alt={"im90s-logo"}
          src={logoWhiteImg}
        />
        IM90S
      </Logo>
      <List>
        {/* list from const definitions */}
        {menu.map(({ text, Icon, url }, index) => (
          <Link to={url} key={index} onClick={toggleDrawer(side, false)}>
            <MenuList>
              <ListItem button>
                <Icon
                  style={{
                    fontSize: "20px",
                    marginRight: "25px",
                    color: color.navSideMenuText,
                  }}
                />
                <ListItemText primary={text} />
              </ListItem>
            </MenuList>
          </Link>
        ))}
        {/* <MenuList>
          <ListItem button onClick={handleClick}>
            <AppstoreOutlined
              style={{
                fontSize: "20px",
                marginRight: "25px",
                color: color.navSideMenuText
              }}
            />
            <ListItemText primary="Articles Category" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        </MenuList> */}
        {/* categories dynamic list */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            onClick={toggleDrawer(side, false)}
          ></List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <div>
      <Drawer open={menuExpand} onClose={toggleDrawer("left", false)}>
        {sideList("left")}
      </Drawer>
    </div>
  );
};

export default SideBar;

const Logo = styled.div`
  width: 100%;
  line-height: 64px;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0.0075em;
  font-weight: 100;
  background-color: ${color.secondary};
  color: ${color.navTextColor};
  font-size: 1.25rem;
`;

const MenuList = styled.div`
  .MuiTypography-body1 {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont,
      "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    letter-spacing: 0.0075em;
    font-size: 0.812rem;
    transition: all 0.3s ease;
    font-weight: 700;
    color: ${color.navSideMenuText};
  }
`;
