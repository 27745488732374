import React from "react";
import { Row, Descriptions } from "antd";
import styled from "styled-components";

const DescriptionsTitle = ({
  title,
  descriptionItem,
  size = "default",
  margin = "30px",
}) => {
  return (
    <DescriptionsTitleWrapper>
      <Row style={{ margin }}>
        <Descriptions
          bordered
          size
          title={title}
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          {descriptionItem &&
            descriptionItem.map((res, index) => {
              return (
                <Descriptions.Item key={index} label={res.label}>
                  {res.val}
                </Descriptions.Item>
              );
            })}
        </Descriptions>
      </Row>
    </DescriptionsTitleWrapper>
  );
};

export default DescriptionsTitle;

const DescriptionsTitleWrapper = styled.div``;
