import React from "react";
import {
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

// immutable
const whiteColor = "#fff";
export const color = {
  primary: "#4DA6BD",
  secondary: "#0F4C5C",
  navMenu: "#0F4C5C",
  navSideMenuText: "#9a9fbf",
  containerBackground: whiteColor,
  navTextColor: whiteColor,
  communityTimeStamp: "#ccc",
  communityStar: "rgba(0, 0, 0, 0.45)",
  primaryTransparentColor: "rgba(77, 105, 136, 0.95)",
};

export const font = {
  primary: '"Roboto", "Helvetica", "Arial", sans-serif',
};

export const messages = {
  errors: {
    login: "ID or password mismatch",
  },
};

const PREFIXED_URI = "/app";

export const menu = [
  {
    text: "Home",
    Icon: (prop) => <TeamOutlined {...prop} />,
    url: `${PREFIXED_URI}/home`,
  },
  {
    text: "Website",
    Icon: (prop) => <UsergroupAddOutlined {...prop} />,
    url: `${PREFIXED_URI}/website`,
  },
];

export const dropDownHeadeMenu = [
  {
    text: "Profile",
    Icon: (prop) => <UserOutlined {...prop} />,
    url: `${PREFIXED_URI}/profile`,
  },
  {
    text: "Settings",
    Icon: (prop) => <ProfileOutlined {...prop} />,
    url: `${PREFIXED_URI}/setting`,
  },
];

export const UPLOADMEDIUM = { avatar: "avatar", banner: "banner" };

export const badgesDetails = {
  register: {
    badgesAvatar: "b1",
    title: "Register Success",
  },
  login: {
    badgesAvatar: "b2",
    title: "Login Success",
  },
  completeProfileDetails: {
    badgesAvatar: "b3",
    title: "Complete Profile Details",
  },
  completeProfile: {
    badgesAvatar: "b4",
    title: "Update Banner Avatars",
  },
  followComm: {
    badgesAvatar: "b5",
    title: "Follow at least 2 Communities",
  },
  postPoint: {
    badgesAvatar: "b6",
    title: "Posts Points",
  },
  followerPoint: {
    badgesAvatar: "b7",
    title: "Followers Points",
  },
};

export const pieChartData = [
  { item: "Malaysia", count: 40, percent: 0.4 },
  { item: "Singapore", count: 21, percent: 0.21 },
  { item: "Australia", count: 17, percent: 0.17 },
  { item: "USA", count: 13, percent: 0.13 },
  { item: "Romania", count: 9, percent: 0.09 },
];

export const barChartData = [
  { year: "1951 年", sales: 38 },
  { year: "1952 年", sales: 52 },
  { year: "1956 年", sales: 61 },
  { year: "1957 年", sales: 45 },
  { year: "1958 年", sales: 48 },
  { year: "1959 年", sales: 38 },
  { year: "1960 年", sales: 38 },
  { year: "1962 年", sales: 38 },
];

export const axisChartData = [
  {
    month: "Jan",
    city: "Tokyo",
    temperature: 7,
  },
  {
    month: "Jan",
    city: "London",
    temperature: 3.9,
  },
  {
    month: "Feb",
    city: "Tokyo",
    temperature: 6.9,
  },
  {
    month: "Feb",
    city: "London",
    temperature: 4.2,
  },
  {
    month: "Mar",
    city: "Tokyo",
    temperature: 9.5,
  },
  {
    month: "Mar",
    city: "London",
    temperature: 5.7,
  },
  {
    month: "Apr",
    city: "Tokyo",
    temperature: 14.5,
  },
  {
    month: "Apr",
    city: "London",
    temperature: 8.5,
  },
  {
    month: "May",
    city: "Tokyo",
    temperature: 18.4,
  },
  {
    month: "May",
    city: "London",
    temperature: 11.9,
  },
  {
    month: "Jun",
    city: "Tokyo",
    temperature: 21.5,
  },
  {
    month: "Jun",
    city: "London",
    temperature: 15.2,
  },
  {
    month: "Jul",
    city: "Tokyo",
    temperature: 25.2,
  },
  {
    month: "Jul",
    city: "London",
    temperature: 17,
  },
  {
    month: "Aug",
    city: "Tokyo",
    temperature: 26.5,
  },
  {
    month: "Aug",
    city: "London",
    temperature: 16.6,
  },
  {
    month: "Sep",
    city: "Tokyo",
    temperature: 23.3,
  },
  {
    month: "Sep",
    city: "London",
    temperature: 14.2,
  },
  {
    month: "Oct",
    city: "Tokyo",
    temperature: 18.3,
  },
  {
    month: "Oct",
    city: "London",
    temperature: 10.3,
  },
  {
    month: "Nov",
    city: "Tokyo",
    temperature: 13.9,
  },
  {
    month: "Nov",
    city: "London",
    temperature: 6.6,
  },
  {
    month: "Dec",
    city: "Tokyo",
    temperature: 9.6,
  },
  {
    month: "Dec",
    city: "London",
    temperature: 4.8,
  },
];
