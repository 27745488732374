import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Card, Button } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  SelectOutlined,
} from "@ant-design/icons";

const { Meta } = Card;

class WebsiteCard extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <WebsiteCardWrapper>
        <Link to={`/app/website/${data.id}`}>
          <Card
            actions={[
              <Button key="setting" type="link">
                <SelectOutlined />
              </Button>,
              <Button key="edit" type="link" disabled>
                <EditOutlined />
              </Button>,
              <Button key="delete" type="link" disabled>
                <DeleteOutlined />
              </Button>,
            ]}
          >
            <Meta
              // avatar={ <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" /> }
              title={
                <div style={{ textTransform: "capitalize" }}>
                  {data.company.text}
                </div>
              }
              description={`ID: ${data.id}`}
            />
          </Card>
        </Link>
      </WebsiteCardWrapper>
    );
  }
}

export default WebsiteCard;

const WebsiteCardWrapper = styled.div`
  margin: 20px 10px;
`;
