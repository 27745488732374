import PropTypes from "prop-types";
import React from "react";
import { navigate, Link } from "gatsby";
import { Avatar, BackTop } from "antd";
import { LogoutOutlined } from "@ant-design/icons";

import { fade, makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import Divider from "@material-ui/core/Divider";
// import SearchIcon from "@material-ui/icons/Search";
// import InputBase from "@material-ui/core/InputBase";

import { isLoggedIn, logout } from "@services/auth";
import { color, dropDownHeadeMenu } from "@helpers/const";

import withThisUser from "@helpers/hoc/withThisUser";

import logoWhiteImg from "@images/logos/logo.png";

export const headerMarginBottom = "20px";

const API_URL = process.env.API_URL;

const Header = ({ siteTitle, triggerMenu, menuExpand, userDetails }) => {
  const theme = createMuiTheme({
    overrides: {
      MuiPaper: {
        root: {
          width: "230px",
        },
      },
    },
  });

  const useStyles = makeStyles((theme) => ({
    appBar: {
      backgroundColor: color.navMenu,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      // marginRight: theme.spacing(2)
      marginRight: "-8px",
    },
    title: {
      color: color.navTextColor,
      display: "block",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const menuMargin = "10px 28px";

  const renderMenu = (
    <ThemeProvider theme={theme}>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: "14px",
            lineHeight: "45px",
            verticalAlign: "middle",
            color: color.primary,
            marginBottom: "5px",
          }}
        >
          MY ACCOUNT
        </div>
        <Divider />
        {dropDownHeadeMenu.map(({ url, Icon, text }, index) => {
          return (
            <Link to={url} key={index}>
              <MenuItem onClick={handleMenuClose}>
                <Icon style={{ margin: menuMargin }} />
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  {text}
                </div>
              </MenuItem>
            </Link>
          );
        })}
        <Divider />
        {isLoggedIn() ? (
          <MenuItem onClick={() => logout(() => navigate(`/login`))}>
            <LogoutOutlined style={{ margin: menuMargin }} />
            Logout
          </MenuItem>
        ) : null}
      </Menu>
    </ThemeProvider>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <ThemeProvider theme={theme}>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <div
          style={{
            textAlign: "center",
            fontSize: "14px",
            lineHeight: "45px",
            verticalAlign: "middle",
            color: color.primary,
            marginBottom: "5px",
          }}
        >
          Your Account
        </div>
        <Divider />
        {dropDownHeadeMenu.map(({ url, Icon, text }, index) => {
          return (
            <Link to={url} key={index}>
              <MenuItem onClick={handleMenuClose}>
                <Icon style={{ margin: menuMargin }} />
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  {text}
                </div>
              </MenuItem>
            </Link>
          );
        })}
        <Divider />
        {isLoggedIn() ? (
          <MenuItem onClick={() => logout(() => navigate(`/login`))}>
            <LogoutOutlined style={{ margin: menuMargin }} />
            Logout
          </MenuItem>
        ) : null}
      </Menu>
    </ThemeProvider>
  );

  return (
    <div className={classes.grow} style={{ marginBottom: headerMarginBottom }}>
      <BackTop />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              triggerMenu(!menuExpand);
            }}
          >
            <MenuIcon />
          </IconButton>
          <img
            style={{ width: "auto", height: "40px", margin: "0" }}
            alt={"im90s-logo"}
            src={logoWhiteImg}
          />
          <Typography className={classes.title} variant="h6" noWrap>
            <span style={{ marginLeft: "15px", fontWeight: "100" }}>IM90S</span>
          </Typography>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div> */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {isLoggedIn() ? (
              <IconButton
                edge="end"
                aria-label="account"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <div
                  style={{
                    fontSize: "12px",
                    marginRight: "7px",
                    textTransform: "uppercase",
                  }}
                >
                  {userDetails && userDetails.first_name}{" "}
                  {userDetails && userDetails.last_name}
                </div>
                <Avatar
                  style={{ backgroundColor: color.primary }}
                  src={
                    userDetails &&
                    userDetails.avatar &&
                    `${API_URL}/${userDetails.avatar.url}`
                  }
                >
                  {userDetails && userDetails.username.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
            ) : (
              <Link to={"/login"}>LOGIN</Link>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default withThisUser(Header);
