import React from "react";
import { Router } from "@reach/router";

import PrivateRoute from "@helpers/hoc/privateRoute";
import Layout from "@components/layout";

import Home from "@views/home";
import Websites from "@views/website";
import Website from "@views/website/id";

import NotFound from "@pages/404";

// import { getUser } from "@services/auth";

const Route = ({ location }) => {
  //custom route redirect that require param goes here

  return (
    <Layout>
      <Router basepath="/app">
        <NotFound default />
        <PrivateRoute path="/home" component={Home} />
        <PrivateRoute path="/website" component={Websites} />
        <PrivateRoute path="/website/:id" component={Website} />
        {/* <Articles path="/article" />
        <Article path="/article/:id" />
        <ArticleCat path="/article/category/:id" />
        <Profile path="/profile">
          <Timeline path="/timeline/:userID" />
          <About path="/about/:userID" />
          <Followers path="/followers/:userID" />
        </Profile> */}
      </Router>
    </Layout>
  );
};

export default Route;
