import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Anchor } from "antd";

import Header from "./header";
import SideBar from "./sideBar";
import "./index.css";

const Layout = ({ children }) => {
  const [menuExpand, setMenuExpand] = React.useState(false);
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Anchor style={{ padding: "0", background: "transparent" }}>
            <Header
              siteTitle={data.site.siteMetadata.title}
              menuExpand={menuExpand}
              triggerMenu={flag => setMenuExpand(flag)}
            />
          </Anchor>
          <SideBar
            menuExpand={menuExpand}
            triggerMenu={flag => setMenuExpand(flag)}
          />
          <div>
            <main>{children}</main>
            {/* <footer>
              © {new Date().getFullYear()}, Built with
              {` `}
              <a href="https://www.gatsbyjs.org">Gatsby</a>
            </footer> */}
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
