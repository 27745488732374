import React from "react";
import { PageHeader, Card, Col, Row } from "antd";
import { Chart, Line, Point, Interval, Tooltip, Coordinate } from "bizcharts";
import styled from "styled-components";

import SEO from "@components/common/seo";
import { barChartData, axisChartData, pieChartData } from "@helpers/const";

const Home = () => {
  const cols = {
    percent: {
      formatter: (val) => {
        val = val * 100 + "%";
        return val;
      },
    },
  };

  const loading = false;
  const chartHeight = 200;
  return (
    <>
      <SEO title="Home" />
      <PageHeader title="Home" subTitle="Overall" />
      <HomeWrapper loading={loading.toString()}>
        <Row gutter={16}>
          <Col span={24} sm={12} md={8}>
            <Card loading={loading} title={"Sites Performance"}>
              <Chart
                scale={{ temperature: { min: 0 } }}
                padding={[10, 20, 50, 40]}
                autoFit
                height={chartHeight}
                data={axisChartData}
                pure
              >
                <Line
                  shape="smooth"
                  position="month*temperature"
                  color="city"
                />
                <Point position="month*temperature" color="city" />
              </Chart>
            </Card>
          </Col>
          <Col span={24} sm={12} md={8}>
            <Card loading={loading} title={"Views"}>
              <Chart height={chartHeight} autoFit data={barChartData} pure>
                <Interval position="year*sales" />
              </Chart>
            </Card>
          </Col>
          <Col span={24} sm={12} md={8}>
            <Card loading={loading} title={"Visitors Country"}>
              <Chart
                height={chartHeight}
                data={pieChartData}
                scale={cols}
                autoFit
                pure
              >
                <Coordinate type="theta" radius={0.75} />
                <Tooltip showTitle={false} />
                <Interval
                  position="percent"
                  adjust="stack"
                  color="item"
                  style={{
                    lineWidth: 1,
                    stroke: "#fff",
                  }}
                  label={[
                    "count",
                    {
                      content: (data) => {
                        return `${data.item}: ${data.percent * 100}%`;
                      },
                    },
                  ]}
                />
              </Chart>
            </Card>
          </Col>
        </Row>
      </HomeWrapper>
    </>
  );
};

export default Home;

const HomeWrapper = styled.div`
  padding: 25px;
  > .ant-card {
    > .ant-card-body {
      padding: ${(props) => (props.loading === "true" ? "24px" : 0)};
    }
  }
`;
